import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import MapView from "./components/MapView";
import Login from "./components/Login";
import "./App.css";
import PrivateRoute from "./components/privateRoutes/PrivateRoute";
import Landing from "./pages/landing/LandingPage";
import Dashboard from "./pages/dashboard/Dashboard";
import ViewTaskMap from "./components/view-task/ViewTaskMap";
import ViewTaskNetwork from "./components/view-task/ViewTaskNetwork";

function App() {
  const [authDetails, setAuthDetails] = useState({});
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const authData = JSON.parse(queryParams.get("q"));
    if (authData) {
      localStorage.removeItem("_auth");
      localStorage.setItem('_auth', JSON.stringify(authData));
      navigate('/dashboard');
      //refreshTokenOrUser(authData);
    } else {
      const storedAuth = localStorage.getItem("_auth");
      if (storedAuth) {
        // handleLogin(JSON.parse(storedAuth), true);
        // navigate('/dashboard')
        refreshTokenOrUser(JSON.parse(storedAuth));
      }
    }
  }, []);

  const handleLogin = (authDetails, isString = false) => {
    setAuthDetails(authDetails);
    if (!isString) {
      localStorage.setItem("_auth", JSON.stringify(authDetails));
    }
    navigate('/dashboard')
  };

  const refreshTokenOrUser = async (authData) => {
    const currentTime = new Date();
    const targetTime = new Date(authData.expire);
  
    if (currentTime < targetTime) {
      const differenceMs = targetTime - currentTime;
      const differenceHours = differenceMs / (1000 * 60 * 60);
  
      if (differenceHours > 1) {
        try {
          const userHeaders = new Headers();
          userHeaders.append("Accept", "application/json");
          userHeaders.append("Authorization", authData._token);
  
          const response = await fetch(
            `${process.env.REACT_APP_INTELLI_SCADA_API_URL}/Token/GetLoggedUserInfo?PROJCD=sfms`,
            {
              method: "GET",
              headers: userHeaders,
              redirect: "follow",
            }
          );
  
          if (!response.ok) throw new Error("Failed to fetch user details");
  
          const userData = await response.json();
          setUser(userData);
          handleLogin(authData);
  
          const cleanUrl = window.location.origin + window.location.pathname;
          window.history.replaceState({}, document.title, cleanUrl);
        } catch (error) {
        }
      } else {
        try {
          const myHeaders = new Headers();
          myHeaders.append("Accept", "application/json");
  
          const response = await fetch(
            `${process.env.REACT_APP_INTELLI_SCADA_API_URL}/Token/Login2?reftoken=${authData.refresh_token}`,
            {
              method: "POST",
              headers: myHeaders,
              redirect: "follow",
            }
          );
  
          if (!response.ok) throw new Error("Network response was not ok");
  
          const result = await response.json();
  
          localStorage.setItem('_auth', JSON.stringify(result.data));
        } catch (error) {
        }
      }
    } else {
      // navigate('/login');
    }
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/map" element={<MapView/>}/>
        <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
        <Route path="/view-task-network" element={<PrivateRoute element={ViewTaskNetwork} />} />
        <Route path="/view-task-map/:id" element={<PrivateRoute element={ViewTaskMap} />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
