// Sidebar.js
import React, { useState, useEffect, useRef } from "react";
import "./css/Sidebar.css";
import html2canvas from "html2canvas";
import drawGeoJSON from "../utils/drawGeoJSON";
import drawGeoJSONMapbook from "../utils/drawGeoJSONMapbook";
import { Canvg } from 'canvg';
import { jsPDF } from 'jspdf';
import { svg2pdf } from 'svg2pdf.js';
import { SVG } from '@svgdotjs/svg.js';
import geojsonToSvg from 'geojson-to-svg';
import Modal from 'react-bootstrap/Modal';
import ModalData from "./modal/Modal";

const LeftSidebar = ({
    sendDataToParent,
    setMapLayer,
    updateCenter,
    addMerkers,
    objectElements,
    toggleObjectElements,
    handleGeneratePDF,
    clearMap,
    updateProjectColor,
    organizations,
    subDivisions,
    divisions,
    setSubDivisions,
    setDivisions,
    setSidebarVisible,
    setblankMapOpen,
    setTaskNetworkVisible,
    setTaskMapVisible,
    toggleRightSidebarVisibility
}) => {
    const leftSubmenuRef = useRef(null);
    const [activeLayer, setActiveLayer] = useState("OpenStreetMap");
    const [organization, setOrganization] = useState([]);
    const [subStatation, setSubStatation] = useState([]);
    const [subStatations, setsubStatations] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [projects, setprojects] = useState([]);
    const [toggleStates, setToggleStates] = useState([]);
    const [openAccordion, setOpenAccordion] = useState(1);
    const [subMenuSection, setSubMenuSection] = useState(null);
    const [options, setOptions] = useState([]);
    const [show, setShow] = useState(false);
    const [isMapbookshow, setIsMapbookshow] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);
    const [formData, setFormData] = useState({
        project_id: "",
    });
    const canvasRef = useRef(null);
    const [lineData, setLineData] = useState(null);
    const [showCanvas, setShowCanvas] = useState(false);
    const [pointData, setPointData] = useState(null);
    // useEffect(() => {
    //     // Fetch categories from API
    //     fetch(process.env.REACT_APP_API_URL + "/api/get-organazations")
    //         .then((response) => response.json())
    //         .then((result) => {
    //             setOrganizations(result.data); // Assuming the API response is an array of category objects
    //             setDivisions(result.data.divisions);
    //             setSubDivisions(result.data.subDivisions);
    //         })
    //         .catch((error) => {
    //         });
    // }, []);

    useEffect(() => {
        let companies = organizations?.companies
        if (companies?.length > 0 && companies[0]) {
            handleOrganizationChange(
                "company",
                companies[0]
            );
            if (companies[0]?.organization_code) {
                updateNetworkDetails(companies[0]?.organization_code);
            }
        }
    }, [organizations]);

    useEffect(() => {
        updateProjectColor(projects);
    }, [projects]);

    // Function to handle toggle switch change
    const handleToggleChange = (index) => {
        const newToggleStates = [...toggleStates];
        newToggleStates[index] = !newToggleStates[index];
        setToggleStates(newToggleStates);
    };

    // Function to add a new toggle switch
    const addToggleSwitch = () => {
        setToggleStates([...toggleStates, false]);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        setSelectedProjects([
            {
                project_id: e.target.value,
            },
        ]);
    };

    //load network on map
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const projectIds = selectedProjects
                .map((item) => item.project_id)
                .join(",");
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            const formdataAPI = new FormData();
            formdataAPI.append("project_ids", projectIds);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdataAPI,
                redirect: "follow",
            };

            const responseLine = await fetch(
                process.env.REACT_APP_API_URL + "/api/get-project-line-data",
                requestOptions
            );
            const line = await responseLine.json();
            setLineData(line); // Store line data

            const response = await fetch(
                process.env.REACT_APP_API_URL + "/api/get-project-point-data",
                requestOptions
            );
            const pointData = await response.json();
            setPointData(pointData); // Store point data

            if (pointData.features === null) {
                window.alert("No Data found.");
                setFormData({
                    project_id: "",
                });
                throw new Error("An error occurred!");
            }

            var array = [];
            pointData.features.map((value, index) => {
                if (value.properties.point_type === "HT Pole") {
                    if (!array.includes("Support Pole")) {
                        array.push("Support Pole");
                    }
                } else {
                    if (!array.includes(value.properties.point_type)) {
                        array.push(value.properties.point_type);
                    }
                }
            });
            setOptions(array);
            sendDataToParent(pointData, line, projects);

        } catch (error) {
            console.error('Error in handleSubmit:', error);
        }
    };

    const _old_generatePDF = async () => {
        try {
            if (!lineData || !pointData) {
                window.alert("No data available for PDF generation.");
                return;
            }
            // Set DPI (300 DPI for higher quality)
            /*const dpi = 300;
            const mmToPxScaleFactor = dpi / 25.4; // Conversion from millimeters to pixels (1 inch = 25.4 mm)

            // PDF size in millimeters (jsPDF uses millimeters as default units when 'px' is not specified)
            const pdfWidthInMM = 297;  // A4 size width in mm (landscape mode)
            const pdfHeightInMM = 210; // A4 size height in mm (landscape mode)

            // Convert PDF size to pixels based on DPI
            const pdfWidthInPx = pdfWidthInMM * mmToPxScaleFactor;
            const pdfHeightInPx = pdfHeightInMM * mmToPxScaleFactor;
            // Create an off-screen canvas for PDF generation
            const canvas = document.createElement('canvas');
            canvas.width = pdfWidthInPx; // Adjust width as needed
            canvas.height = pdfHeightInPx; // Adjust height as needed
            const ctx = canvas.getContext('2d');
            if (!ctx) throw new Error('Failed to get canvas context');

            // Draw lines and points on the off-screen canvas
            drawLinesOnCanvas(lineData, ctx);
            drawPointsOnCanvas(pointData, ctx);

            // Convert canvas to image
            const imgData = canvas.toDataURL('image/png');

            // Create a new jsPDF instance
            const pdf = new jsPDF('l', 'px', [canvas.width, canvas.height]);
            //pdf.addImage(imgData, 'PNG', 0, 0);

            // Set margin
            const margin = 30;

            // Adjust image position with margin
            pdf.addImage(imgData, 'PNG', margin, margin, canvas.width - 2 * margin, canvas.height - 2 * margin);


            pdf.save('download.pdf'); // Save the PDF file*/
            // --------------------------------------------------------
            // testSVG(createSVG(lineData, pointData));
            // return false;
            // Create SVG string
            const svgString = await createSVG(lineData, pointData);
            // Create a new jsPDF instance
            const pdf = new jsPDF('l', 'mm', 'a4'); // A4 size in millimeters

            // Load SVG and convert to PDF
            const svgElement = new DOMParser().parseFromString(svgString, 'image/svg+xml').documentElement;
            const pdfWidth = 297;  // A4 width in mm
            const pdfHeight = 210; // A4 height in mm

            // Create a canvas for rendering
            const canvas = document.createElement('canvas');
            canvas.width = pdfWidth * 3;  // Scale for higher resolution
            canvas.height = pdfHeight * 3; // Scale for higher resolution
            const ctx = canvas.getContext('2d');

            // Render SVG to canvas
            const svgDoc = SVG(canvas);
            svgDoc.svg(svgString);

            // Convert canvas to PDF using svg2pdf
            svg2pdf(svgElement, pdf, {
                xOffset: 0,
                yOffset: 0,
                scale: 1
            });

            // Save the PDF file
            pdf.save('download.pdf');

        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    const generatePDF = async () => {
        try {
            // Create SVG string
            // const svgString = await createSVG();

            // // Create a new jsPDF instance
            // const pdf = new jsPDF('l', 'mm', 'a4'); // A4 size in millimeters

            // if (svgString) {
            //     // Parse the SVG string into a DOM element
            //     const svgElement = new DOMParser().parseFromString(svgString, 'image/svg+xml').documentElement;

            //     // Convert SVG directly to PDF
            //     svg2pdf(svgElement, pdf, {
            //         xOffset: 0,
            //         yOffset: 0,
            //         scale: 1  // Scale as needed
            //     });

            //     // Save the PDF file
            //     pdf.save('download.pdf');
            // } else {
            //     console.error("SVG string is empty or undefined.");
            // }

            const pdf = new jsPDF('l', 'mm', 'a4'); // A4 size in millimeters

            // Define margin values (in millimeters)
            const margin = 30; // Margin of 10mm on all sides

            // Create an off-screen canvas
            const scaleFactor = 6; // Increase this value for better resolution
            const canvas = document.createElement('canvas');
            canvas.width = (297 - 2 * margin) * scaleFactor;
            canvas.height = (210 - 2 * margin) * scaleFactor;
            const ctx = canvas.getContext('2d');

            // Create SVG string (simplified for testing)
            const svgString = await createSVG(); //'<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><circle cx="50" cy="50" r="40" fill="red" /></svg>';

            // Use Canvg to render the SVG to the canvas
            const v = Canvg.fromString(ctx, svgString);

            // Render the SVG onto the canvas
            await v.render();

            // Convert canvas to image data
            const imgData = canvas.toDataURL('image/png');

            // Calculate image dimensions considering the margins
            const pdfWidth = 297; // A4 width
            const pdfHeight = 210; // A4 height
            const imgWidth = pdfWidth - 2 * margin;
            const imgHeight = pdfHeight - 2 * margin;

            // Add image to the PDF with margins
            pdf.addImage(imgData, 'PNG', margin, margin, imgWidth, imgHeight);  // Position it according to your margin values

            // Save the PDF file
            pdf.save('download.pdf');

        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    const testSVG = (svgString) => {
        const div = document.createElement('div');
        div.innerHTML = svgString;
        document.body.appendChild(div);
    };

    const svgToDataURL = (svgString) => {
        const encodedSVG = encodeURIComponent(svgString);
        return `data:image/svg+xml;charset=utf-8,${encodedSVG}`;
    };

    const _old_createSVG = (lineData, pointData) => {
        const svgNamespace = "http://www.w3.org/2000/svg";

        // Create SVG element
        const svg = document.createElementNS(svgNamespace, "svg");
        svg.setAttribute("width", "297mm");  // A4 size width in mm
        svg.setAttribute("height", "210mm"); // A4 size height in mm
        svg.setAttribute("viewBox", "0 0 297 210"); // Set viewBox for scaling

        // Function to draw lines
        const drawLinesOnSVG = (lineData) => {
            if (!lineData || !lineData.features) return;

            const lineWidth = 0.5; // Line width in pixels

            lineData.features.forEach((feature) => {
                //if (feature.geometry.type === 'LineString') {
                const line = document.createElementNS(svgNamespace, "polyline");
                const points = feature.geometry.coordinates.map(([x, y]) => `${x},${y}`).join(" ");
                line.setAttribute("points", points);
                line.setAttribute("stroke", "black");
                line.setAttribute("stroke-width", lineWidth);
                line.setAttribute("fill", "black");
                svg.appendChild(line);
                //}
            });
        };

        // Function to draw points
        const drawPointsOnSVG = (pointData) => {
            if (!pointData || !pointData.features) return;

            const pointRadius = 1; // Point radius in pixels

            pointData.features.forEach((feature) => {
                //if (feature.geometry.type === 'Point') {
                const circle = document.createElementNS(svgNamespace, "circle");
                const [x, y] = feature.geometry.coordinates;
                circle.setAttribute("cx", x);
                circle.setAttribute("cy", y);
                circle.setAttribute("r", pointRadius);
                circle.setAttribute("fill", "red");
                svg.appendChild(circle);
                //}
            });
        };

        drawLinesOnSVG(lineData);
        drawPointsOnSVG(pointData);
        console.log(svg);

        return svg.outerHTML;
    };

    const createSVG = async () => {
        const svgNamespace = "http://www.w3.org/2000/svg";

        // Create SVG element
        const svg = document.createElementNS(svgNamespace, "svg");
        svg.setAttribute("width", "297mm");  // A4 size width in mm
        svg.setAttribute("height", "210mm"); // A4 size height in mm
        svg.setAttribute("viewBox", "0 0 297 210"); // Set viewBox for scaling
        svg.setAttribute("xmlns", svgNamespace);
        svg.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");

        // Calculate the bounding box (min/max values) of all features
        let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

        const updateBounds = (x, y) => {
            if (x < minX) minX = x;
            if (y < minY) minY = y;
            if (x > maxX) maxX = x;
            if (y > maxY) maxY = y;
        };
        // Collect bounds from line data
        if (lineData && lineData.features) {
            lineData.features.forEach((feature) => {
                if (feature.geometry && feature.geometry.coordinates) {
                    feature.geometry.coordinates.forEach(([x, y]) => {
                        updateBounds(x, y);
                    });
                }
            });
        } else {
            console.error("Line data is not defined or has no features.");
        }

        // Collect bounds from point data
        if (pointData && pointData.features) {
            pointData.features.forEach((feature) => {
                if (feature.geometry && feature.geometry.coordinates) {
                    const [x, y] = feature.geometry.coordinates;
                    updateBounds(x, y);
                }
            });
        } else {
            console.error("Point data is not defined or has no features.");
        }

        // Scale and translate functions
        const scaleX = 297 / (maxX - minX);
        const scaleY = 210 / (maxY - minY);
        const scale = Math.min(scaleX, scaleY);  // Choose the smaller scale to fit within the SVG

        const translateX = (x) => (x - minX) * scale;
        const translateY = (y) => (maxY - y) * scale; // Flip Y coordinate to match SVG's origin at the top left

        // Function to draw lines
        const drawLinesOnSVG = (lineData) => {
            if (!lineData || !lineData.features) return;

            const lineWidth = 0.5; // Line width in pixels

            lineData.features.forEach((feature) => {
                const line = document.createElementNS(svgNamespace, "polyline");
                const points = feature.geometry.coordinates
                    .map(([x, y]) => `${translateX(x)},${translateY(y)}`)
                    .join(" ");
                line.setAttribute("points", points);
                line.setAttribute("stroke", "#0080ff");
                line.setAttribute("stroke-width", lineWidth);
                line.setAttribute("fill", "none");
                svg.appendChild(line);
            });
        };

        // Function to draw points
        const drawPointsOnSVG = (pointData) => {
            if (!pointData || !pointData.features) return;

            const pointRadius = 0.4; // Point radius in pixels

            pointData.features.forEach((feature) => {
                if (feature.properties.point_type !== "HT Pole") {
                    let imgURL = "map_icons/ht_pole.svg"; // Default icon URL for HT pole
                    switch (feature.properties.point_type) {
                        case "Existing Pole(Double Circuit)":
                            imgURL = "map_icons/existing_pole.svg";
                            break;
                        case "Fuse":
                            imgURL = "map_icons/Fuse.svg";
                            break;
                        case "CTPT":
                            imgURL = "map_icons/CTPT.svg";
                            break;
                        case "HT Pole":
                            imgURL = "map_icons/ht_pole.svg";
                            break;
                        case "Switch":
                            imgURL = "map_icons/Switch.svg";
                            break;
                        case "Gentry":
                            imgURL = "map_icons/Gentry.svg";
                            break;
                        case "Transformer":
                            imgURL = "map_icons/Transformer.svg";
                            break;
                        case "HT Route Point":
                            imgURL = "map_icons/ht_route_point.svg";
                            break;
                        case "RMU":
                            imgURL = "map_icons/RMU.svg";
                            break;
                        case "RMU with TC":
                            imgURL = "map_icons/rmu_with_tc.svg";
                            break;
                    }

                    // const circle = document.createElementNS(svgNamespace, "circle");
                    // const [x, y] = feature.geometry.coordinates;
                    // circle.setAttribute("cx", translateX(x));
                    // circle.setAttribute("cy", translateY(y));
                    // circle.setAttribute("r", pointRadius);
                    // circle.setAttribute("fill", "#0080ff");
                    // svg.appendChild(circle);

                    // Create an image element
                    const image = document.createElementNS(svgNamespace, "image");
                    image.setAttributeNS('http://www.w3.org/1999/xlink', 'href', imgURL);
                    const [x, y] = feature.geometry.coordinates; // Coordinates where the image will be placed
                    const imageWidth = 2; // Width of the image
                    const imageHeight = 2; // Height of the image

                    image.setAttribute("x", translateX(x) - 1); // X position
                    image.setAttribute("y", translateY(y) - 1); // Y position
                    image.setAttribute("width", imageWidth); // Image width
                    image.setAttribute("height", imageHeight); // Image height

                    // Append the image to the SVG
                    svg.appendChild(image);

                }
            });
        };

        drawLinesOnSVG(lineData);
        drawPointsOnSVG(pointData);
        console.log(svg);

        return svg.outerHTML;

        //  const svgContent = `
        //     <svg xmlns="http://www.w3.org/2000/svg" width="297mm" height="210mm">
        //     <rect x="10" y="10" width="100" height="100" fill="blue" />
        //     <circle cx="150" cy="150" r="50" fill="red" />
        //     </svg>
        // `;
        // return svgContent;
    };

    // Function to group GeoJSON features by project_id
    const groupByProjectId = (geojson) => {
        return geojson.features.reduce((acc, feature) => {
        const projectId = feature.project_id;
    
        if (!acc[projectId]) {
            acc[projectId] = [];
        }
    
        acc[projectId].push(feature);
        return acc;
        }, {});
    };

    const handlePrintMapbookPDF = () => {
        if (lineData && pointData) {
            const mergedGeoJSON = mergeGeoJSON(lineData, pointData);
            const groupedData = groupByProjectId(mergedGeoJSON);
            console.log(groupedData);
            drawGeoJSONMapbook(objectElements, groupedData, toggleStates, 'a4', false, false ) // Change 'a4' to 'a3', 'a2', etc. for different page sizes
        } else {
            window.alert("No data available for PDF generation.");
        }
    };

    const handlePrintPDF = (options) => {
        if (lineData && pointData) {
            const mergedGeoJSON = mergeGeoJSON(lineData, pointData);
            if(options.isMapbook){
                const groupedData = groupByProjectId(mergedGeoJSON);
                console.log(groupedData);
                drawGeoJSONMapbook(objectElements, groupedData, toggleStates, options.selectedSize, options.toggleXMRCapacity, options.toggleLineLengthLabel);  // Change 'a4' to 'a3', 'a2', etc. for different page sizes
            } else {
                drawGeoJSON(objectElements, mergedGeoJSON, toggleStates, options.selectedSize, options.toggleXMRCapacity, options.toggleLineLengthLabel);  // Change 'a4' to 'a3', 'a2', etc. for different page sizes
            }
            //generatePDF();
        } else {
            window.alert("No data available for PDF generation.");
        }
    };

    const handleViewTaskNetwork = () => {
        toggleRightSidebarVisibility(false);
        setTaskNetworkVisible(true);
        setTaskMapVisible(false)
    };

    const mergeGeoJSON = (lineGeojson, pointGeojson) => {
        if (lineGeojson.type === "FeatureCollection" && pointGeojson.type === "FeatureCollection") {
            return {
                type: "FeatureCollection",
                features: [...lineGeojson.features, ...pointGeojson.features],
            };
        } else {
            throw new Error("Both inputs must be GeoJSON FeatureCollections");
        }
    };

    const drawLinesOnCanvas = (lineData, ctx) => {
        if (!ctx) {
            console.error('Canvas context is not available');
            return;
        }

        if (!lineData || !lineData.features) return;

        // Calculate bounds for scaling
        let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

        lineData.features.forEach((feature) => {
            if (feature.geometry.type === 'LineString') {
                feature.geometry.coordinates.forEach(([x, y]) => {
                    if (x < minX) minX = x;
                    if (y < minY) minY = y;
                    if (x > maxX) maxX = x;
                    if (y > maxY) maxY = y;
                });
            }
        });

        const scaleX = ctx.canvas.width / (maxX - minX);
        const scaleY = ctx.canvas.height / (maxY - minY);
        const scale = Math.min(scaleX, scaleY); // Use the smaller scale to fit within canvas

        // Adjust line width for smaller size
        const lineWidth = 0.5; // Further reduce the line width

        lineData.features.forEach((feature) => {
            if (feature.geometry.type === 'LineString') {
                const coordinates = feature.geometry.coordinates;
                if (coordinates.length > 0) {
                    ctx.beginPath();

                    const [startX, startY] = coordinates[0];
                    ctx.moveTo((startX - minX) * scale, ctx.canvas.height - (startY - minY) * scale);

                    coordinates.slice(1).forEach(([x, y]) => {
                        ctx.lineTo((x - minX) * scale, ctx.canvas.height - (y - minY) * scale);
                    });

                    ctx.strokeStyle = 'black'; // Line color
                    ctx.lineWidth = lineWidth; // Line width
                    ctx.stroke();
                }
            }
        });
    };

    const drawPointsOnCanvas = (pointData, ctx) => {
        if (!ctx) {
            console.error('Canvas context is not available');
            return;
        }

        if (!pointData || !pointData.features) return;

        // Set up point styling
        const pointRadius = 1; // Further reduce the radius of the point
        const pointColor = 'red'; // Color of the point

        // Calculate bounds for scaling
        let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

        pointData.features.forEach((feature) => {
            if (feature.geometry.type === 'Point') {
                const [x, y] = feature.geometry.coordinates;
                if (x < minX) minX = x;
                if (y < minY) minY = y;
                if (x > maxX) maxX = x;
                if (y > maxY) maxY = y;
            }
        });

        const scaleX = ctx.canvas.width / (maxX - minX);
        const scaleY = ctx.canvas.height / (maxY - minY);
        const scale = Math.min(scaleX, scaleY); // Use the smaller scale to fit within canvas

        pointData.features.forEach((feature) => {
            if (feature.geometry.type === 'Point') {
                const [x, y] = feature.geometry.coordinates;

                // Scale and adjust coordinates
                const scaledX = (x - minX) * scale;
                const scaledY = ctx.canvas.height - (y - minY) * scale;

                // Draw the point
                ctx.beginPath();
                ctx.arc(scaledX, scaledY, pointRadius, 0, 2 * Math.PI, false);
                ctx.fillStyle = pointColor;
                ctx.fill();
            }
        });
    };

    // Event handler for category select change
    const handleOrganizationChange = (level, orgItem) => {
        setOrganization((prevOrganization) => {
            const existingItems = prevOrganization[level] || [];
            const isItemExist = existingItems.some(
                (item) => item.value === orgItem.value
            );

            if (isItemExist) {
                // If the item already exists, remove it
                return {
                    ...prevOrganization,
                    [level]: existingItems.filter(
                        (item) => item.value !== orgItem.value
                    ),
                };
            } else {
                // If the item doesn't exist, add it
                return {
                    ...prevOrganization,
                    [level]: [...existingItems, orgItem],
                };
            }
        });
    };

    useEffect(() => {
        // Code to execute after organization state has been updated
        setDivisions(filterDivisions());
    }, [organization]);

    useEffect(() => {
        // Code to execute after organization state has been updated
        setSubDivisions(filtersubDivisions());
    }, [divisions]);

    useEffect(() => {
        addMerkers(subStatation);

        // Filter out null or undefined values
        const validSubStationValues = subStatation
            .map(item => item.value)
            .filter(value => value !== null && value !== undefined);

        // Check if there are any valid values
        if (validSubStationValues.length > 0) {
            // Code to execute after organization state has been updated
            const formdata = new FormData();
            formdata.append(
                "ss_ids",
                validSubStationValues
            );
            const requestOptions = {
                method: "POST",
                body: formdata,
                redirect: "follow",
            };
            fetch(
                process.env.REACT_APP_API_URL + `/api/get-projects-of-substation`,
                requestOptions
            )
            .then((response) => response.json())
            .then((result) => {
                setprojects(result.data); // Assuming the API response is an array of category objects
            })
            .catch((error) => {
            });
        }
    }, [subStatation]);

    // Function to filter subdivisions based on parent organization code
    function filterCircles() {
        if (organization?.company) {
            const filteredCircles = [];
            const organizationCodes = organization.company.map(
                (item) => item.organization_code
            );
            organizationCodes.forEach((parentOrganizationCode) => {
                const parentCodeString = parentOrganizationCode
                    .toString()
                    .substr(0, 2); // Extract first 4 digits
                const circle = organizations.circle.filter((circle) => {
                    const circleCodeString =
                        circle.organization_code.toString();
                    // Check if the sub-division's organization code matches the pattern
                    return circleCodeString.startsWith(parentCodeString);
                });
                filteredCircles.push(...circle);
            });
            return filteredCircles;
        }
    }

    function filterDivisions() {
        const filteredDivisions = [];
        if (organization?.circle) {
            const organizationCodes = organization.circle.map(
                (item) => item.organization_code
            );
            organizationCodes.forEach((parentOrganizationCode) => {
                const parentCodeString = parentOrganizationCode
                    .toString()
                    .substr(0, 4); // Extract first 4 digits
                const divisions = organizations.divisions.filter((division) => {
                    const divisionCodeString =
                        division.organization_code.toString();
                    // Check if the sub-division's organization code matches the pattern
                    return divisionCodeString.startsWith(parentCodeString);
                });
                filteredDivisions.push(...divisions);
            });
        }
        return filteredDivisions.length
            ? filteredDivisions
            : organizations.divisions;
    }

    function filtersubDivisions() {
        const filteredSubDivisions = [];
        if (organization?.division) {
            const organizationCodes = organization.division.map(
                (item) => item.organization_code
            );
            organizationCodes.forEach((parentOrganizationCode) => {
                const parentCodeString = parentOrganizationCode
                    .toString()
                    .substr(0, 6); // Extract first 4 digits
                const subDivisions = organizations.subDivisions.filter(
                    (subDivision) => {
                        const subDivisionCodeString =
                            subDivision.organization_code.toString();
                        // Check if the sub-division's organization code matches the pattern
                        return subDivisionCodeString.startsWith(
                            parentCodeString
                        );
                    }
                );
                filteredSubDivisions.push(...subDivisions);
            });
        }
        return filteredSubDivisions.length
            ? filteredSubDivisions
            : organizations.subDivisions;
    }

    const handleSubStatationChange = (subStatationJson) => {
        const index = subStatation.findIndex(
            (item) => JSON.stringify(item) === JSON.stringify(subStatationJson)
        );

        if (index === -1) {
            // JSON not present, add it to the array
            setSubStatation((prevArray) => [...prevArray, subStatationJson]);
        } else {
            // JSON already present, remove it from the array
            setSubStatation((prevArray) => {
                const newArray = [...prevArray];
                newArray.splice(index, 1);
                return newArray;
            });
        }

        setSelectedProjects([]);
        setprojects([]);
    };

    const handleProjectChange = (projectJson) => {
        //setSelectedProjects([selectedProjects]);
        const index = selectedProjects.findIndex(
            (item) => JSON.stringify(item) === JSON.stringify(projectJson)
        );

        if (index === -1) {
            // JSON not present, add it to the array
            setSelectedProjects((prevArray) => [...prevArray, projectJson]);
        } else {
            // JSON already present, remove it from the array
            setSelectedProjects((prevArray) => {
                const newArray = [...prevArray];
                newArray.splice(index, 1);
                return newArray;
            });
        }
    };

    const toggleAccordion = (accordionNumber) => {
        setOpenAccordion(
            openAccordion === accordionNumber ? null : accordionNumber
        );
    };

    const removeClass = () => {
        if (leftSubmenuRef.current) {
            leftSubmenuRef.current.classList.remove("visible");
            setSubMenuSection(null);
        }
    };

    const openLeftSubmenu = (section) => {
        setTaskNetworkVisible(false)
        setTaskMapVisible(false)
        if (subMenuSection === section) {
            setSubMenuSection(null);
        } else {
            setSubMenuSection(section);
        }
    };

    const handleOrganizationNetwork = () => {
        var organizationId = null;
        if (organization.hasOwnProperty("subDivision")) {
            organizationId = organization.subDivision.map(
                (item) => item.organization_code
            );
        } else if (organization.hasOwnProperty("division")) {
            organizationId = organization.division.map(
                (item) => item.organization_code
            );
        } else if (organization.hasOwnProperty("circle")) {
            organizationId = organization.circle.map(
                (item) => item.organization_code
            );
        } else if (organization.hasOwnProperty("company")) {
            organizationId = organization.company.map(
                (item) => item.organization_code
            );
        }

        if (organizationId) {
            updateNetworkDetails(organizationId);
            openLeftSubmenu("network");
        } else {
            window.alert("Please select an organization.");
        }
    };

    const updateNetworkDetails = (organizationId) => {
        const formdata = new FormData();
        formdata.append("organization_code", organizationId);

        const requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };
        fetch(
            process.env.REACT_APP_API_URL +
            `/api/get-substation-of-organization`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                var latLongArray = result.data.map((item) => [
                    item.lat,
                    item.lng,
                ]);
                updateCenter(latLongArray);
                setsubStatations(result.data); // Assuming the API response is an array of category objects
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleMapChange = (mapImage) => {
        setMapLayer(mapImage);
        setActiveLayer(mapImage);
    };

    // Function to check if an organization value exists in the Organizations
    const isValueExist = (array, targetValue) => {
        return array.some((item) => item.value === targetValue);
    };

    const handleColorChange = (project_id, color) => {
        setprojects(projects.map(item =>
            item.project_id === project_id ? { ...item, color } : item
        ));
    }

    const handleClose = () => {
        setShow(false)
        setSidebarVisible(true)
    };

    const handleOpenModal = (isMapbookVisible) => {
        setShow(true)
        setSidebarVisible(false)
        setIsMapbookshow(isMapbookVisible)
    };

    const clearMapAndSelections = () => {
        clearMap();
        setSubStatation([]);
        setSelectedProjects([]);
    }

    const handleReportViewClick = () => {
        const storedAuth = localStorage.getItem("_auth");
        const url = storedAuth ? process.env.REACT_APP_REPORT_VIEW_URL + `/?q=${storedAuth}` : 'https://reports.fornaxenergytech.com/';
        window.open(url, '_blank'); 
    };

    return (
        <>
            <ModalData
                setblankMapOpen={handlePrintPDF}
                show={show}
                selectedProjects={selectedProjects}
                fullscreen={fullscreen}
                onHide={handleClose}
                visibleElements={objectElements}
                setShow={setShow}
                setSidebarVisible={setSidebarVisible}
                toggleObjectElements={toggleObjectElements}
                setToggleProjects={setToggleStates}
                isMapbookshow={isMapbookshow}
            />
            <div className="leftsidebar">
                <div className="main-menu">
                    <ul className="leftsidebar-menu">
                        <li
                            className={`${subMenuSection == "organization" ? "active" : "" }`}
                        >
                            <a
                                onClick={() => openLeftSubmenu("organization")}
                                title="Organization"
                            >
                                <img
                                    src="organization.svg"
                                    className="sidebar-icon"
                                    alt=""
                                />
                                <label>Organization</label>
                            </a>
                            {/* <ReactTooltip id="OrganizationTip" place="right" effect="solid">
                            Organization
                        </ReactTooltip> */}
                        </li>
                        <li
                            className={`${subMenuSection == "network" ? "active" : "" }`}
                        >
                            <a
                                onClick={() => openLeftSubmenu("network")}
                                title="Network"
                            >
                                <img
                                    src="Network.svg"
                                    className="sidebar-icon"
                                    alt=""
                                />
                                <label>Network</label>
                            </a>
                        </li>
                        <li
                            className={`${subMenuSection == "base-map" ? "active" : "" }`}
                        >
                            <a
                                onClick={() => openLeftSubmenu("base-map")}
                                title="Basemap"
                            >
                                <img
                                    src="Base-map-blue.svg"
                                    className="sidebar-icon"
                                    alt=""
                                />
                                <label>Basemap</label>
                            </a>
                        </li>
                        <li
                            className={`${subMenuSection == "objects" ? "active" : "" }`}
                        >
                            <a onClick={() => openLeftSubmenu("objects")} title="Objects">
                                <img
                                    src="Objects.svg"
                                    className="sidebar-icon"
                                    alt=""
                                />
                                <label>Objects</label>
                            </a>
                        </li>
                        <li
                            className={`${subMenuSection == "view-task-network" ? "active" : "" }`}
                        >
                            <a onClick={() => openLeftSubmenu("view-task-network")} title="Tasks">
                                <img
                                    src="Task.svg"
                                    className="sidebar-icon"
                                    alt="tasks"
                                />
                                <label>Tasks</label>
                            </a>
                        </li>
                        <li>
                            <a onClick={() => handleReportViewClick()} title="Reports">
                                <img
                                    src="report.svg"
                                    className="sidebar-icon"
                                    alt="reports"
                                    title="Reports"
                                />
                                <label>Reports</label>
                            </a>
                        </li>
                        <li
                            className={`${subMenuSection == "print" ? "active" : "" }`}
                        >
                            <a onClick={() => openLeftSubmenu("print")} title="Print">
                                <img
                                    src="Print.svg"
                                    className="sidebar-icon"
                                    alt=""
                                />
                                <label>Print</label>
                            </a>
                        </li>
                        <li>
                            <a href="#" title="Help">
                                <img
                                    src="help.svg"
                                    className="sidebar-icon"
                                    alt="help"
                                    title="Help"
                                />
                                <label>Help</label>
                            </a>
                        </li>
                    </ul>
                </div>
                <div
                    ref={leftSubmenuRef}
                    className={`sub-sidebar-wrap sub-menu ${subMenuSection !== null ? "visible" : "" }`}
                >
                    {organizations.companies ? (
                        <ul
                            data-parent="organization"
                            className={`${subMenuSection == "organization"
                                ? "d-block"
                                : "d-none"
                                }`}
                        >
                            <li>
                                <h6 className="sub-menu-title mb-0">
                                    Organization
                                </h6>
                            </li>
                            <li>
                                <div className="acoordion-wrap">
                                    <div
                                        className={`accordion ${openAccordion === "company"
                                            ? "open"
                                            : ""
                                            }`}
                                    >
                                        <div
                                            className="accordion-header"
                                            onClick={() =>
                                                toggleAccordion("company")
                                            }
                                        >
                                            <h3>
                                                <img src="Company.svg" alt="" />{" "}
                                                Company
                                            </h3>
                                            <span
                                                className={`arrow ${openAccordion === "company"
                                                    ? "open"
                                                    : ""
                                                    }`}
                                            ></span>
                                        </div>
                                        <div className="accordion-content">
                                            <ul>
                                                {organizations.companies.map(
                                                    (item, index) => (
                                                        <li key={index}>
                                                            <a
                                                                href="#"
                                                                onClick={() =>
                                                                    handleOrganizationChange(
                                                                        "company",
                                                                        item
                                                                    )
                                                                }
                                                                className={
                                                                    isValueExist(
                                                                        organization?.company ||
                                                                        [],
                                                                        item.value
                                                                    )
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                            >
                                                                {item.label}
                                                            </a>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                {organizations.circle ? (
                                    <div className="acoordion-wrap">
                                        <div
                                            className={`accordion ${openAccordion === "circle"
                                                ? "open"
                                                : ""
                                                }`}
                                        >
                                            <div
                                                className="accordion-header"
                                                onClick={() =>
                                                    toggleAccordion("circle")
                                                }
                                            >
                                                <h3>
                                                    <img src="Circle.svg" alt="" />{" "}
                                                    Circle
                                                </h3>
                                                <span
                                                    className={`arrow ${openAccordion === "circle"
                                                        ? "open"
                                                        : ""
                                                        }`}
                                                ></span>
                                            </div>
                                            <div className="accordion-content">
                                                <ul>
                                                    {organizations.circle.map(
                                                        (item, index) => (
                                                            <li key={index}>
                                                                <a
                                                                    href="#"
                                                                    onClick={() =>
                                                                        handleOrganizationChange(
                                                                            "circle",
                                                                            item
                                                                        )
                                                                    }
                                                                    className={
                                                                        isValueExist(
                                                                            organization?.circle ||
                                                                            [],
                                                                            item.value
                                                                        )
                                                                            ? "active"
                                                                            : ""
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </a>
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <label>No circle found</label>
                                )}
                            </li>
                            <li>
                                {organizations.divisions ? (
                                    <div className="acoordion-wrap">
                                        <div
                                            className={`accordion ${openAccordion === "divisions"
                                                ? "open"
                                                : ""
                                                }`}
                                        >
                                            <div
                                                className="accordion-header"
                                                onClick={() =>
                                                    toggleAccordion("divisions")
                                                }
                                            >
                                                <h3>
                                                    <img
                                                        src="Division.svg"
                                                        alt=""
                                                    />{" "}
                                                    Division
                                                </h3>
                                                <span
                                                    className={`arrow ${openAccordion ===
                                                        "divisions"
                                                        ? "open"
                                                        : ""
                                                        }`}
                                                ></span>
                                            </div>
                                            <div className="accordion-content">
                                                <ul>
                                                    {divisions.map(
                                                        (item, index) => (
                                                            <li key={index}>
                                                                <a
                                                                    href="#"
                                                                    onClick={() =>
                                                                        handleOrganizationChange(
                                                                            "division",
                                                                            item
                                                                        )
                                                                    }
                                                                    className={
                                                                        isValueExist(
                                                                            organization?.division ||
                                                                            [],
                                                                            item.value
                                                                        )
                                                                            ? "active"
                                                                            : ""
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </a>
                                                            </li>
                                                        )
                                                    )}
                                                    {/* {organizations.divisions.map((item, index) => (
                                                        <li key={index}><a href="#" onClick={() => handleOrganizationChange('division', item)} className={isValueExist(organization?.division || [], item.value) ? 'active' : ''}>{item.label}</a></li>
                                                    ))} */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <label>No division found</label>
                                )}
                            </li>
                            <li>
                                {organizations.subDivisions ? (
                                    <div className="acoordion-wrap">
                                        <div
                                            className={`accordion ${openAccordion === "subDivisions"
                                                ? "open"
                                                : ""
                                                }`}
                                        >
                                            <div
                                                className="accordion-header"
                                                onClick={() =>
                                                    toggleAccordion("subDivisions")
                                                }
                                            >
                                                <h3>
                                                    <img
                                                        src="Sub-division.svg"
                                                        alt=""
                                                    />{" "}
                                                    Sub Division
                                                </h3>
                                                <span
                                                    className={`arrow ${openAccordion ===
                                                        "subDivisions"
                                                        ? "open"
                                                        : ""
                                                        }`}
                                                ></span>
                                            </div>
                                            <div className="accordion-content">
                                                <ul>
                                                    {subDivisions.map(
                                                        (item, index) => (
                                                            <li key={index}>
                                                                <a
                                                                    href="#"
                                                                    onClick={() =>
                                                                        handleOrganizationChange(
                                                                            "subDivision",
                                                                            item
                                                                        )
                                                                    }
                                                                    className={
                                                                        isValueExist(
                                                                            organization?.subDivision ||
                                                                            [],
                                                                            item.value
                                                                        )
                                                                            ? "active"
                                                                            : ""
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </a>
                                                            </li>
                                                        )
                                                    )}
                                                    {/* {organizations.subDivisions.map((item, index) => (
                                          <li key={index}><a href="#" onClick={() => handleOrganizationChange('subDivision', item)} className={isValueExist(organization?.subDivision || [], item.value) ? 'active' : ''}>{item.label}</a></li>
                                        ))} */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <label>No sub division found</label>
                                )}
                            </li>
                            <li>
                                <button
                                    className="load-map-btn mt-3 w-100"
                                    onClick={handleOrganizationNetwork}
                                    type="button"
                                >
                                    View Network
                                </button>
                            </li>
                        </ul>
                    ) : (
                        <label>No data found</label>
                    )}
                    <ul
                        data-parent="network"
                        className={`${subMenuSection == "network" ? "d-block" : "d-none"
                            }`}
                    >
                        <li>
                            <h6 className="sub-menu-title mb-0">Power Network</h6>
                        </li>
                        {subStatations?.length ? (
                            <>
                                <li>
                                    <div className="acoordion-wrap">
                                        <div
                                            className={`accordion ${openAccordion === 4 ? "open" : ""
                                                }`}
                                        >
                                            <div
                                                className="accordion-header"
                                                onClick={() => toggleAccordion(4)}
                                            >
                                                <h3>
                                                    <img src="Extra-High-Voltage.svg" alt="" /> EHV (Sub-Station)
                                                </h3>
                                                <span
                                                    className={`arrow ${openAccordion === 4
                                                        ? "open"
                                                        : ""
                                                        }`}
                                                ></span>
                                            </div>
                                            <div className="accordion-content">
                                                <ul>
                                                    {subStatations.map(
                                                        (item, index) => (
                                                            <li key={index}>
                                                                <a
                                                                    href="#"
                                                                    onClick={() =>
                                                                        handleSubStatationChange(
                                                                            item
                                                                        )
                                                                    }
                                                                    className={
                                                                        isValueExist(
                                                                            subStatation ||
                                                                            [],
                                                                            item.value
                                                                        )
                                                                            ? "active"
                                                                            : ""
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </a>
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="acoordion-wrap">
                                        <div
                                            className={`accordion ${openAccordion === 2 ? "open" : ""
                                                }`}
                                        >
                                            <div
                                                className="accordion-header"
                                                onClick={() => toggleAccordion(2)}
                                            >
                                                <h3>
                                                    <img
                                                        src="HV-Network.svg"
                                                        alt=""
                                                    /> HV Network (Feeder)
                                                </h3>
                                                <span
                                                    className={`arrow ${openAccordion === 2
                                                        ? "open"
                                                        : ""
                                                        }`}
                                                >
                                                    {" "}
                                                </span>
                                            </div>
                                            <div className="accordion-content">
                                                {projects?.length ? (
                                                    <ul>
                                                        {projects.map(
                                                            (item, index) => (
                                                                <li key={index} className="d-flex align-items-center">
                                                                    <input
                                                                        type="color" className="color-picker-form-control me-2"
                                                                        value={item.color} style={{ background: item.color, outlineColor: item.color }}
                                                                        onChange={(e) => handleColorChange(item.project_id, e.target.value)}
                                                                    />
                                                                    <a
                                                                        href="#"
                                                                        onClick={() =>
                                                                            handleProjectChange(
                                                                                item
                                                                            )
                                                                        }
                                                                        className={
                                                                            isValueExist(
                                                                                selectedProjects ||
                                                                                [],
                                                                                item.value
                                                                            )
                                                                                ? "active"
                                                                                : ""
                                                                        }
                                                                    >
                                                                        {item.label}{" "}
                                                                        (
                                                                        {
                                                                            item.project_id
                                                                        }
                                                                        )
                                                                    </a>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                ) : (
                                                    <label>No data found</label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="acoordion-wrap">
                                        <div
                                            className={`accordion ${openAccordion === 3 ? "open" : ""
                                                }`}
                                        >
                                            <div
                                                className="accordion-header"
                                                onClick={() => toggleAccordion(3)}
                                            >
                                                <h3>
                                                    <img
                                                        src="LV-Network.svg"
                                                        alt=""
                                                    /> LV Network (Transformer)
                                                </h3>
                                                <span
                                                    className={`arrow ${openAccordion === 3
                                                        ? "open"
                                                        : ""
                                                        }`}
                                                ></span>
                                            </div>
                                            <div className="accordion-content">
                                                <ul>
                                                    <li>
                                                        <a href="#">LV Network</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <form onSubmit={handleSubmit}>
                                        <button
                                            className="load-map-btn mt-3 w-100"
                                            disabled={selectedProjects.length === 0}
                                            type="submit"
                                        >
                                            View Map
                                        </button>
                                    </form>
                                    <button className="load-map-btn mt-3 w-100" onClick={clearMapAndSelections} >Clear Map</button>
                                </li>
                            </>
                        ) : (
                            <li>
                                <label>No organization selected</label>
                            </li>
                        )}
                    </ul>
                    <ul
                        data-parent="base-map"
                        className={`${subMenuSection == "base-map" ? "d-block" : "d-none"
                            }`}
                    >
                        <li>
                            <h6 className="sub-menu-title mb-0">Basemap</h6>
                        </li>
                        <li>
                            <a
                                href="#"
                                className={`${activeLayer === "empty" ? "active" : ""
                                    }`}
                                onClick={() => handleMapChange("empty")}
                            >
                                <img src="Open-Street-Map.svg" alt="" /> Canvas
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                className={`${activeLayer === "OpenStreetMap" ? "active" : ""
                                    }`}
                                onClick={() => handleMapChange("OpenStreetMap")}
                            >
                                <img src="Open-Street-Map.svg" alt="" /> Open Street
                                Map
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                className={`${activeLayer === "Satellite" ? "active" : ""
                                    }`}
                                onClick={() => handleMapChange("Satellite")}
                            >
                                <img src="Satellite-image.svg" alt="" /> Satellite
                                image
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                className={`${activeLayer === "BingMaps" ? "active" : ""
                                    }`}
                                onClick={() => handleMapChange("BingMaps")}
                            >
                                <img src="Bing-Map.svg" alt="" /> Bing Map
                            </a>
                        </li>
                    </ul>
                    <ul
                        data-parent="objects"
                        className={`${subMenuSection == "objects" ? "d-block" : "d-none"
                            }`}
                    >
                        <li>
                            <h6 className="sub-menu-title mb-0">Objects</h6>
                        </li>
                        {Object.keys(objectElements).map((value, key) => (
                            <li key={key}>
                                <div class="switch form-check custom-form-check form-switch">
                                    <label className="form-check-label d-block">
                                        <input className="form-check-input"
                                            type="checkbox" role="switch"
                                            checked={objectElements[value]}
                                            onChange={() => toggleObjectElements(value)}
                                        />

                                        {value.charAt(0).toUpperCase() +
                                            value.slice(1).replace(/_/g, " ")}</label>
                                    {/* <span className="slider round"></span> */}
                                </div>
                            </li>
                        ))}
                    </ul>
                    <ul
                        data-parent="print"
                        className={`${subMenuSection == "view-task-network" ? "d-block" : "d-none"
                            }`}
                    >
                        <li>
                            <h6 className="sub-menu-title mb-0">Tasks</h6>
                        </li>
                        <li>
                            <a href="#" onClick={handleViewTaskNetwork}>
                                <img src="Task-dark.svg" alt="task-list" /> History
                            </a>
                        </li>
                    </ul>
                    <ul
                        data-parent="print"
                        className={`${subMenuSection == "print" ? "d-block" : "d-none"
                            }`}
                    >
                        <li>
                            <h6 className="sub-menu-title mb-0">Print</h6>
                        </li>
                        <li>
                            {/* <a href="#" onClick={handleOpenModal}  handlePrintPDF>
                                <img src="Map-Printing-Options.svg" alt="" /> Print Map PDF
                            </a> */}
                            <a href="#" onClick={() => handleOpenModal(false)}>
                                <img src="Map-Printing-Options.svg" alt="" /> Print Map PDF
                            </a>
                            <a href="#" onClick={() => handleOpenModal(true)}> {/*handlePrintMapbookPDF*/}
                                <img src="Map-Printing-Options.svg" alt="" /> Print Mapbook PDF
                            </a>
                        </li>
                    </ul>
                    <button className="close-submenu" onClick={removeClass}>
                        <img src="left-double-chevron.png" alt="" />
                    </button>
                    {showCanvas && (
                        <div style={{ position: 'relative', width: '100%', height: '500px' }}>
                            <canvas
                                ref={canvasRef}
                                width={1000}
                                height={500}
                                style={{ border: '1px solid #000', display: 'block' }}
                            ></canvas>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default LeftSidebar;
